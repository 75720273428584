<template>
	<div class="loader-wrapper">
		<div class="spinners-wrapper">
			<span>U</span>
			<div>
				<div />
			</div>
			<div>
				<div />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/variables.scss';

@keyframes spinner-animation {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader-wrapper {
	height: 100%;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.spinners-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0;
		width: 250px;
		height: 250px;

		span {
			font-size: 6rem;
			font-family: $logoText;
		}

		& > div {
			position: absolute;
			width: 190px;
			height: 190px;
			border-radius: 50%;
			border: 10px solid #000;
			border-color: $surface-300 transparent $surface-600 transparent;
			animation: spinner-animation 2s linear infinite;
			&:nth-child(2) {
				width: 156px;
				height: 156px;
				animation: spinner-animation 6s linear infinite reverse;
				border-color: transparent $surface-600 transparent $surface-300;
			}
		}
	}
}
</style>
