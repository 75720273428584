import { defineStore } from 'pinia';

// Axios
import axios from 'axios';

// Socket io
import { lobbySocket } from '@/socket';

// Stores
import { useNotificationsStore } from '@/store/Notifications';

// Types
import { PlayerUserDetails } from '@sharedTypes/auth/dto/get-player-user.dto';
import { UpdatePlayerRequestDto } from '@sharedTypes/players/dto/update-player.dto';
import { TeamWithPlayersAndTournamentsDetails } from '@/submodules/shared-types/teams/dtos/get-team.dto';

interface UserState {
	_user: PlayerUserDetails | null;
	_userTeams: TeamWithPlayersAndTournamentsDetails[];
	_loggedIn: boolean;
	_isLoading: boolean;
}

export const useUserStore = defineStore('user', {
	state: (): UserState => ({
		_user: null,
		_userTeams: [],
		_loggedIn: false,
		_isLoading: false
	}),
	getters: {
		loggedIn: (state) => state._loggedIn,
		isLoading: (state) => state._isLoading,
		user: (state) => state._user,
		userTeams: (state) => state._userTeams
	},
	actions: {
		async fetchUserData() {
			const userReponse = await axios.get('auth/players/me');
			const { user } = userReponse.data;
			this._user = user;
		},

		async fetchUserTeams() {
			if (!this.user) return [];
			// Poner /me/teams ?
			const response = await axios.get(`players/${this.user.uuid}/teams`);
			const { teams } = response.data;
			this._userTeams = teams;
		},

		async updateUser(
			uuid: string,
			modificationData: UpdatePlayerRequestDto
		) {
			if (Object.keys(modificationData).length <= 0) return;

			if (modificationData.name) {
				modificationData.name = modificationData.name
					.toLowerCase()
					.trim();
			}

			try {
				await axios.patch(`/players/${uuid}`, {
					...modificationData
				});
			} catch (err: any) {
				console.error('Failed to update player: ', err);

				const { message, statusCode } = err.response.data;

				let errorMessage =
					'No se pudieron guardar los cambios de usuario';

				if (statusCode === 400 || statusCode === 401)
					errorMessage = message;

				throw new Error(errorMessage);
			}
		},

		initializeSocketConnection() {
			if (!this._user) return;
			// state.matches = this.userMatches;

			const lobbySessionID = localStorage.getItem('lobbySessionID');
			const lobbySessionTeam = localStorage.getItem('lobbySessionTeam');

			lobbySocket.auth = { lobbySessionID: lobbySessionID || null };

			lobbySocket.connect();
		},

		setAuthorizationHeader(payload?: string | undefined | null) {
			if (payload) localStorage.setItem('auth-token', payload);
			else localStorage.removeItem('auth-token');

			axios.defaults.headers.common.Authorization = payload
				? `Bearer ${payload}`
				: '';
		},

		async setUser(accessToken: string) {
			try {
				this._isLoading = true;
				this.setAuthorizationHeader(accessToken);

				// Fetch user Data
				await this.fetchUserData();
				// this.initializeSocketConnection();

				this._loggedIn = true;
				this._isLoading = false;
			} catch (err) {
				console.error('Failed to set player', err);
				this._loggedIn = false;
				this._isLoading = false;
			}
		},

		async loginUser(email: string, password: string) {
			const notificationsStore = useNotificationsStore();
			try {
				const response = await axios.post('auth/players/login', {
					email,
					password
				});

				const accessToken = response.data.user.token;

				await this.setUser(accessToken);
				await notificationsStore.fetchNotifications();
			} catch (err: any) {
				console.error('Failed to login player: ', err);

				const { message, statusCode } = err.response.data;

				let errorMessage =
					'Error al ingresar a la plataforma, por favor vuelva a intentarlo mas tarde';

				if (statusCode === 400 || statusCode === 401)
					errorMessage = message;

				throw new Error(errorMessage);
			}
		},

		async registerUser(userName: string, email: string, password: string) {
			try {
				await axios.post('auth/players/register', {
					email,
					password,
					name: userName
				});

				await this.loginUser(email, password);
			} catch (err: any) {
				console.error('Failed to register user: ', err);

				const { message, statusCode } = err.response.data;

				let errorMessage =
					'Error al registrar usuario a la plataforma, por favor vuelva a intentarlo mas tarde';

				if (statusCode === 400 || statusCode === 401)
					errorMessage = message;

				throw new Error(errorMessage);
			}
		},

		async updateProfileImage(uuid: string, profileImg: File) {
			const formData = new FormData();
			formData.append('file', profileImg);
			await axios.patch(`/players/${uuid}/profileImg`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		},

		logOut() {
			localStorage.removeItem('auth-token');
			this._loggedIn = false;
			this._user = null;
		}
	}
});
