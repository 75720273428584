import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2881e53c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "slot-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: [..._ctx.btnClass, _ctx.severity, _ctx.size]
  }), [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
          key: 0,
          class: "btn-icon"
        }))
      : _createCommentVNode("", true),
    (!_ctx.rounded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 16))
}