import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Services
import { useUserStore } from '@/store/User';
import { useNotificationsStore } from '@/store/Notifications';

const routes: Array<RouteRecordRaw> = [
	// Home
	{ path: '/', name: 'home', redirect: { name: 'tournaments' } },

	// Tournaments
	{
		path: '/torneos',
		name: 'tournaments',
		component: () => import('@/views/tournaments/TournamentsView.vue')
	},
	{
		path: '/torneos/:uuid',
		name: 'tournament',
		props: true,
		component: () => import('@/views/tournaments/TournamentView.vue')
	},

	// Matches
	{
		path: '/partidos',
		name: 'matches',
		component: () => import('@/views/matches/MatchesView.vue')
	},

	// Ranking
	{
		path: '/ranking/',
		name: 'ranking',
		component: () => import('@/views/ranking/RankingView.vue')
	},

	// Teams
	{
		path: '/equipos/:uuid',
		name: 'teamProfile',
		props: true,
		component: () => import('@/views/teams/TeamProfileView.vue')
	},
	{
		path: '/equipos/:uuid/invitaciones',
		name: 'teamPlayerInvitation',
		props: true,
		component: () => import('@/views/players/PlayerInvitationView.vue')
	},

	// Players
	{
		path: '/jugador/:uuid',
		name: 'playerProfile',
		props: true,
		component: () => import('@/views/players/PlayerProfileView.vue')
	},

	// Matches
	{
		path: '/partidos/:uuid',
		name: 'match',
		props: true,
		component: () => import('@/views/matches/MatchView.vue')
	},

	// Settings
	{
		path: '/ajustes',
		name: 'settings',
		props: false,
		component: () => import('@/views/settings/UserSettings.vue')
	},

	// Lobby
	{
		path: '/lobby',
		name: 'lobby',
		props: false,
		component: () => import('@/views/lobby/Lobby.vue')
	},

	// Authentication
	{
		path: '/login',
		name: 'login',
		props: false,
		component: () => import('@/views/auth/LoginView.vue')
	},
	{
		path: '/register',
		name: 'register',
		props: false,
		component: () => import('@/views/auth/RegisterView.vue')
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

const publicRoutes: string[] = [
	'tournaments',
	'tournament',
	'ranking',
	'playerProfile',
	'teamProfile',
	'lobby'
];

router.beforeEach(async (to, from, next) => {
	const userStore = useUserStore();
	const notificationsStore = useNotificationsStore();

	const queryAuthToken = to.query.authToken as string;
	const storedAuthToken = localStorage.getItem('auth-token');

	const authToken = queryAuthToken ? queryAuthToken : storedAuthToken;

	if (authToken && !userStore.loggedIn) {
		await userStore.setUser(authToken);
		await notificationsStore.fetchNotifications();
	}

	const isPublic =
		typeof to.name === 'string' && publicRoutes.includes(to.name);
	if (isPublic) return next();
	else if (
		userStore.loggedIn &&
		(to.path === '/login' || to.path === '/register')
	)
		next({ name: 'home' });
	else if (
		!userStore.loggedIn &&
		to.path !== '/login' &&
		to.path !== '/register'
	)
		next({ name: 'login' });

	return next();
});

export default router;
