
import { computed, defineComponent, ref } from 'vue';

// Icons
import { IconEye, IconEyeClosed } from '@tabler/icons-vue';

export default defineComponent({
	name: 'MInput',
	components: {
		IconEye,
		IconEyeClosed
	},
	props: {
		modelValue: {
			type: [String, Number],
			required: true
		},
		inputDelay: {
			type: Number,
			required: false,
			default: 0
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		smallText: {
			type: String,
			required: false,
			default: ''
		},
		severity: {
			type: String as () => 'danger' | 'info' | 'warning' | 'success',
			required: false,
			default: 'info'
		},
		outlined: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['update:modelValue', 'input'],
	setup(props, { emit }) {
		let timeoutId: number | null = null;
		const handleInput = (input: Event) => {
			const inputElement = input.target as HTMLInputElement;
			emit('update:modelValue', inputElement.value);

			if (timeoutId) clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				emit('input', inputElement.value);
			}, props.inputDelay);
		};

		const inputRef = ref();

		const isPassHidden = ref(false);

		const handlePassVisibility = () => {
			isPassHidden.value = !isPassHidden.value;
			if (isPassHidden.value) inputRef.value.type = 'text';
			else inputRef.value.type = 'password';
		};

		const inputClass = computed(() => {
			const inputClass = [];
			if (props.outlined) inputClass.push('outlined');
			if (props.label) inputClass.push('labeled');
			return inputClass;
		});

		return {
			inputRef,
			inputClass,
			handleInput,
			isPassHidden,
			handlePassVisibility
		};
	}
});
