
import { defineComponent, computed } from 'vue';
import {
	IconTrophy,
	IconUserCircle,
	IconSwords,
	IconSortAscending2,
	IconSettings,
	IconLogout,
	IconDeviceGamepad
} from '@tabler/icons-vue';

import { useUserStore } from '@/store/User';

// VueRouter
import { useRouter } from 'vue-router';

// Components
import Notifications from '@/components/notifications/Notifications.vue';

export default defineComponent({
	name: 'SideBar',
	components: {
		// Components
		Notifications,

		// Icons
		IconTrophy,
		IconUserCircle,
		IconSwords,
		IconSortAscending2,
		IconDeviceGamepad
	},
	props: {
		isExpanded: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['log-out', 'settings', 'expand'],
	setup(props, { emit }) {
		// Vue Router
		const router = useRouter();

		// Stores
		const userStore = useUserStore();

		const user = computed(() => userStore.user);
		const currentPathName = computed(() => router.currentRoute.value.name);

		const logOut = async () => {
			emit('log-out');
		};

		const navigationItems = computed(() => [
			{
				to: '/torneos',
				icon: IconTrophy,
				text: 'Torneos',
				key: 'tournaments'
			},
			{
				to: '/partidos',
				icon: IconDeviceGamepad,
				text: 'Mis Partidas',
				key: 'matches'
			},
			{
				to: `/jugador/${user.value?.uuid}`,
				icon: IconUserCircle,
				text: 'Mi Perfil',
				key: 'playerProfile'
			},
			{
				to: '/ranking',
				icon: IconSortAscending2,
				text: 'Ranking',
				key: 'ranking'
			},
			{
				to: '/lobby',
				icon: IconSwords,
				text: 'Scrims',
				key: 'lobby'
			}
		]);

		const handleExpansion = () => {
			emit('expand');
		};

		return {
			user,
			navigationItems,
			currentPathName,
			logOut,
			handleExpansion,

			// Icons
			IconSettings,
			IconLogout
		};
	}
});
