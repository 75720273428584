// Pinia
import { defineStore } from 'pinia';

// Axios
import axios from 'axios';

// Stores
import { useUserStore } from '@/store/User';

// Types
import { TeamInviteNotification } from 'Notifications';

interface NotificationState {
	_notifications: TeamInviteNotification[];
}

export const useNotificationsStore = defineStore('notifications', {
	state: (): NotificationState => ({
		_notifications: []
	}),
	getters: {
		notifications: (state) => state._notifications
	},
	actions: {
		async fetchNotifications() {
			const userStore = useUserStore();

			const user = userStore.user;
			if (!user) return;

			const request = await axios.get(
				`/notifications/player/${user.uuid}`
			);
			this._notifications = request.data.notifications;
		}
	}
});
