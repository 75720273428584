import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9471284e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "initials"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MImage = _resolveComponent("MImage")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "containerRef",
    style: _normalizeStyle(_ctx.style),
    class: "avatar"
  }, [
    (_ctx.imgUrl)
      ? (_openBlock(), _createBlock(_component_MImage, {
          key: 0,
          src: _ctx.imgUrl,
          class: "image",
          alt: _ctx.name
        }, null, 8, ["src", "alt"]))
      : (_ctx.name)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.name[0]), 1))
        : _createCommentVNode("", true)
  ], 4))
}