
import DeferredContent from 'primevue/deferredcontent';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'MImage',
	components: {
		DeferredContent
	},
	inheritAttrs: false
});
