import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeferredContent = _resolveComponent("DeferredContent")!

  return (_openBlock(), _createBlock(_component_DeferredContent, { class: "content-wrapper" }, {
    default: _withCtx(() => [
      _createElementVNode("img", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16)
    ]),
    _: 1
  }))
}